<template>
  <div class="page-login mt5">
    <el-card class="card">
      <login @afterLogin="redirectTo" />
    </el-card>
  </div>
</template>

<script>
import login from "../common/login/login.component.vue";

export default {
  components: {
    login,
  },
  mounted: function() {
    localStorage.clear();
  },
  methods: {
    redirectTo: function() {
      const redirectPath = this.$route.query.redirect;
      if (redirectPath === "checkout") {
        // this.$router.push("/checkout");
      } else {
        this.$router.push("/dashboard/saved/all");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "login-page.component.scss";
</style>
